/* GOAL: Make this file as small as possible by applying w3.css classes in HTML */

body {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

.overlay {
  background: url('/images/pale_blue_tiling.jpg') repeat;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensures the flex container spans the full width */
}

h1 { font-size: 3em; }
h2 { font-size: 1.75em; }
h3 { font-size: 1.5em; }
h4 { font-size: 1.25em; }
h5 { font-size: 1em; }
h6 { font-size: .9em; }

/* Mobile styles */
@media (max-width: 768px) {
  h1 { font-size: 2em; } /* Adjust to a size that looks good on mobile */
  h2 { font-size: 1.5em; }
  h3 { font-size: 1.25em; }
  h4 { font-size: 1.125em; }
  h5 { font-size: 1em; }
  h6 { font-size: 0.875em; }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Ubuntu', serif;
  font-weight: 700;
}

.social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.social-links li {
  margin-left: 20px;
}

.social-links li a {
  font-size: 24px;
  color: #ffffff;
  transition: color 0.3s ease;
}

.social-links li a:hover {
  color: #ddd;
}

.footer-text {
  margin: 0;
  font-size: 16px;
}
